<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <div v-if="inspectionType==='conditions'" :style="{'padding-left':0, 'padding-right':0}"
           class="md-layout-item md-layout md-size-30 md-small-size-100 md-alignment-top-space-between md-gutter">
        <md-button class="md-success md-layout-item md-size-30 md-small-size-100" @click="newConditionInspection">
          {{ $t('button.new_inspection') }}
        </md-button></div>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>fact_check</md-icon>
          </div>
          <h4 class="title">{{ $t('label.inspections') }}</h4>
        </md-card-header>
        <md-card-content>
          <md-table
              :value="queriedData"
              :md-sort.sync="currentSort"
              :md-sort-order.sync="currentSortOrder"
              :md-sort-fn="customSort"
              class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">{{ $t('label.per_page') }}</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    :placeholder="$t('label.search_records')"
                    v-model="searchQuery"
                ></md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{item}">

              <md-table-cell :md-label="$t('inspection.modified_by')" md-sort-by="modified_by">
                <div class="md-layout md-layout-item md-alignment-center-left modified-link">
                  <md-button
                      class="md-just-icon md-primary md-simple" @click="carouselShow(item.inspection_id)"
                      :title="$t('file.list_of_files')">
                    <md-icon>collections</md-icon>
                  </md-button>

                  <md-button v-if='viewItemForm!==""'
                             class="md-just-icon md-primary md-simple" @click="onGoToView(item.inspection_id)"
                             :title="$t('file.go_to_details')">
                    <md-icon>file_open</md-icon>
                  </md-button>


                  <span class="images-link">{{ item.modified_by }}</span>

                </div>

              </md-table-cell>
              <md-table-cell :md-label="$t('inspection.created_at')" md-sort-by="created_at">
                {{ item.created_at | toDate }}
              </md-table-cell>
              <md-table-cell :md-label="$t('inspection.updated_at')" md-sort-by="updated_at">
                {{ item.updated_at | toDate }}
              </md-table-cell>


            </md-table-row>
          </md-table>

        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              {{
                $t('label.showing_from_to_of_entries', {
                  from: to === 0 ? 0 : from + 1,
                  to: to,
                  total
                })
              }}
            </p>
          </div>
          <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
    <div class="carousel-block" v-if="isCarouselShow">
      <div class="close-carousel" @click="carouselHide">
        <md-icon>clear</md-icon>
      </div>
      <el-carousel height="500px">
        <template v-if="carouselImages.length===0">
          <h3>{{ $t("inspection.no_photo") }}</h3>
        </template>
        <el-carousel-item v-for="item in carouselImages" :key="item.file_url">
          <img :src="item.file_url" alt="" class="carousel-block-image">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import {Pagination} from '@/components'

export default {
  name: "Inspections",
  props: {
    inspectionType: String,
    assetType: String,
    viewItemForm: String
  },
  components: {
    Pagination
  },
  data() {
    return {
      assetId: this.$route.params.aid,
      inspections: [],
      isCarouselShow: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      currentSort: 'key',
      currentSortOrder: 'asc',
      searchQuery: '',
      propsToSearch: ['key'],
      searchedData: [],
      fuseSearch: null,
      carouselImages: []
    }
  },
  filters: {
    toDate: function (value) {
      return value ? value.substring(0, 10) + ' ' + value.substring(11, 19) : ""
    }
  },
  async created() {
    const asset_type = this.assetType
    const asset_id = this.assetId
    const inspection_type = this.inspectionType
    this.inspections = await this.$store.dispatch("LOAD_BRIDGE_INSPECTION_DATA", {asset_type, asset_id, inspection_type})
  },
  computed: {
    queriedData() {
      const result = !this.searchQuery
          ? this.tableData
          : this.searchedData
              ? this.searchedData
              : []
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
    tableData() {
      return this.inspections
    }
  },
  methods: {
    newConditionInspection() {
      const bid = String(this.assetId)
      this.$router.push({name: "bridge-condition", params: {bid: bid}})
    },
    onGoToView(inspId) {
      const bid = String(this.assetId)
      this.$router.push({name: this.viewItemForm, params: {bid: bid, iid: inspId}})
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'asc') {
          return a[sortBy]
              .toString()
              .localeCompare(b[sortBy].toString(), undefined, {numeric: true})
        }
        return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, {numeric: true})
      })
    },
    carouselHide() {
      this.isCarouselShow = false
    },
    async carouselShow(id) {

      this.carouselImages = []
      const inspection_id = id
      const inspection_type = this.inspectionType
      const inspection_asset_type = this.assetType
      const inspection_asset_id = this.assetId

      const payload = {
        inspection_id,
        inspection_type,
        inspection_asset_type,
        inspection_asset_id
      }
      this.carouselImages = await this.$store.dispatch("LOAD_BMS_INSPECTION_FILES", payload)
      this.isCarouselShow = true
    }
  }
}
</script>

<style lang="scss" scoped>

.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 1.5rem !important;
}

.modified-link {
  .images-link {
    padding-left: 10px;
  }

}

.el-carousel__container {
  position: relative;
  height: 40em;
}

.el-carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;


  img {
    max-height: 100%;
    width: auto !important;
  }


}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.el-carousel {
  text-align: center;

  h3 {

    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 500px;
    margin: 0;
  }
}

.carousel-block {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 98.5%;
  z-index: 100;
  background-color: #d3dce6;

  .close-carousel {
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 101;
    cursor: pointer;
  }
}
</style>
